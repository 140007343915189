import {TimeRange} from "../../Time.types";
import {ValueType} from "../../line-items";
import {FinancialValueType} from "../../fin-math/FinancialValueTypes";
import {PersistenceQuery} from "../PersistenceQuery";
import {AggregatorMethod} from "../../line-item-utils/aggregators";
export enum AggregateOver {
  TIME_INDEX = "time-index",
  CURRENT_RANGE= "current-range"
}
export type GridColumnOverride = Record<string, {
  label?: string;
  aggregateOver?: AggregateOver;
}>
export interface ResultOptions {
  withMetadata?: string[],
  withLineItemNameColumn?: boolean,
  showGranularity?: boolean,
  grid?: {
    rowDimension: string,
    rowDimensionLabel: string,
    columnDimension: string,
    defaultAggregateOver: AggregateOver;
    currentRange?: TimeRange;
    lineItemAggregations?: LineItemAggregationsResultOption,
    columnOverrides?: GridColumnOverride,
    aggregateColumnConfig?: {}
    aggregateRowConfig?: {}
    doNotAlphabeticallySortRowsByName?:boolean
  }
  aggregatedTable?: {
    currentRange: TimeRange;
    defaultAggregateOver: AggregateOver;
    lineItemAggregations?: LineItemAggregationsResultOption
  },
  table?: {
    rowDimensionFields?: string[],
    aggregateColumnConfig?: { excludedItems?: string[] }
  },
  streamTable?: {
    onData?: () => void,
    rowDimensionFields?: string[],
    aggregateColumnConfig?: { excludedItems?: string[] }
  },

  valueFormatter?: (value: ValueType, valueType: FinancialValueType, decimalPlacesInValue?: number) => string,

  defaultFormat?: FinancialValueType,
  //Default True, if false, it will only include the parents of the line items if they are explicitly selected in the query
  forceParentInclude?: boolean,
  sourceQuery?: PersistenceQuery,
  sortedNames?: string[]
}

interface LineItemAggregationsResultOption {
  lineItemMap?: Record<string, AggregatorMethod>,
  defaultAggregator?: AggregatorMethod
}